import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  notification,
  Radio,
} from "antd";
import React, { SetStateAction, useEffect, useState } from "react";
import editIcon from "../../../assets/images/edit.svg";
import { API_SERVICE } from "../../../shared/services/api-services";
import { getEnumList, getEnumValue } from "../../../shared/Utility/Utility";
import "../ProductJourney/ProductJourney.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import {
  setJourneyObject,
  setScreenTitle,
} from "../../../shared/redux/common-reducer";
import FormButton from "../../../shared/component/FormButton/FormButton";
import FormSelect from "../../../shared/component/FormSelect/FormSelect";
import { viewTypeJourneyProduct } from "./Journey";
import DecisionModel from "./DecisionModel";

const { Title } = Typography;

interface IJourneyFooterNew {
  workflowType: any;
  productWorkflowId: any;
  isStep: any;
  decisioningData: any;
  decisioningOptions: any;
  journeyData: any;
  setJourneyData: any;
  stageIndex: any;
  stepsIndex: any;
  step: any;
  stage: any;
  decisioningList: any;
  getProductWorkflowById: any;
  displayConfigDecisionList: any;
  nextAssistedJourney: any;
  vasList: any;
  inactiveVasList: any;
  vasData: any;
  setVasData: any;
  isSaveDisable: any;
  setViewTypeJourney: React.Dispatch<
    React.SetStateAction<viewTypeJourneyProduct>
  >;
}

const JourneyFooterNew: React.FunctionComponent<IJourneyFooterNew> = (
  props: IJourneyFooterNew
) => {
  const {
    workflowType,
    productWorkflowId,
    isStep,
    decisioningData,
    decisioningOptions,
    journeyData,
    setJourneyData,
    stageIndex,
    stepsIndex,
    decisioningList,
    getProductWorkflowById,
    nextAssistedJourney,
    step,
    stage,
    displayConfigDecisionList,
    isSaveDisable,
    vasData,
    setVasData,
    vasList,
    inactiveVasList,
    setViewTypeJourney
  } = props;

  const [dropOfStageStep, setDropOfStageStep] = useState(null as any);
  const [caseStatusStep, setCaseStatusStep] = useState(null as any);
  const [runTypeStage, setRunTypeStage] = useState(null as any);
  const [eligibilityDecision, setEligibilityDecision] = useState(null as any);
  const [offerDecision, setOfferDecision] = useState(null as any);
  const [isBackend, setIsBackend] = useState(false);
  const [isSkippable, setIsSkippable] = useState();
  const [configValueVAS, setConfigValueVAS] = useState("");
  const [configResultValVAS, setConfigResultValVAS] = useState(false);
  const [isDecisionModalVAS, SetIsDecisionModalVAS] = useState(false);
  const [vasIndex, setVasIndex] = useState(0);
  const [formDecision] = Form.useForm() as any;
  const [formVas] = Form.useForm() as any;
  const dispatch = useDispatch();
  const checkJourneyBuilderConfiguration = isStep
    ? journeyData?.stages?.at(stageIndex)?.steps?.at(stepsIndex)
      ?.journeyBuilderConfiguration
    : journeyData?.stages?.at(stageIndex)?.journeyBuilderConfiguration;
  const checkUserTaskDefinitionKey = isStep
    ? journeyData?.stages?.at(stageIndex)?.steps?.at(stepsIndex)
      ?.userTaskDefinitionKey
    : journeyData?.stages?.at(stageIndex)?.userTaskDefinitionKey;

  useEffect(() => {
    formDecision.setFieldsValue({
      postDecision: decisioningData?.map((data: any) => data.id),
    });
  }, [decisioningData, decisioningOptions]);

  useEffect(() => {
    let tmpObj = isStep ? step : stage;
    setDropOfStageStep(tmpObj?.dropOffStage);
    setCaseStatusStep(tmpObj?.assistedJourneyNextStep);
  }, [isStep ? step : stage])

  const onPostDecision = (key: any) => {
    const ids = key?.map((id: any) => {
      return { id: id };
    });

    const tmpJourney = { ...journeyData };
    if ((tmpJourney?.stages[stageIndex]?.steps?.length ?? 0) > 0) {
      tmpJourney.stages[stageIndex].steps[stepsIndex].decisioning =
        (ids?.length ?? 0) > 0 ? ids : null;
    } else {
      tmpJourney.stages[stageIndex].decisioning =
        (ids?.length ?? 0) > 0 ? ids : null;
    }
    setJourneyData(journeyData);
  };

  const onDropOfStageStatus = (key: any) => {
    setDropOfStageStep(key);
    const tmpJourney = { ...journeyData };
    if (isStep) {
      tmpJourney.stages[stageIndex].steps[stepsIndex].dropOffStage = key;
    } else {
      tmpJourney.stages[stageIndex].dropOffStage = key;
    }
    setJourneyData(tmpJourney);
  };
  const onStepCaseStatus = (key: any) => {
    setCaseStatusStep(key);
    const tmpJourney = { ...journeyData };
    if (isStep) {
      tmpJourney.stages[stageIndex].steps[stepsIndex].assistedJourneyNextStep = key;
    } else {
      tmpJourney.stages[stageIndex].assistedJourneyNextStep = key;
    }
    setJourneyData(tmpJourney);
  };

  const updatejourney = () => {
    const tmpJourney = JSON.parse(JSON.stringify(journeyData));

    const inactiveVasIds = new Set(inactiveVasList.map((obj: any) => obj.id));

    if (isStep) {
      tmpJourney.stages[stageIndex].steps[stepsIndex].vas?.forEach((obj: any) => {
        if (inactiveVasIds.has(obj.id)) {
          obj.isActive = false;
        }
      });
    } else {
      tmpJourney.stages[stageIndex].vas?.forEach((obj: any) => {
        if (inactiveVasIds.has(obj.id)) {
          obj.isActive = false;
        }
      });
    }
    setJourneyData(tmpJourney);

    const payload = {
      productWorkflowConfig: tmpJourney,
    };
    API_SERVICE.updateProductJourney(productWorkflowId, payload)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        getProductWorkflowById();
        setViewTypeJourney('journey');
        dispatch(setScreenTitle("Products Journeys"));
        dispatch(setJourneyObject({}));
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const onVasCheck = (
    e: any,
    selectedVas: any,
    type: "check" | "skip" | "select" | "backEnd" | "return" | "decisionSelect",
    selectSource?: "eligibility" | "offer"
  ) => {
    const tmpJourney = { ...journeyData };

    let vasObj: any;
    if (isStep) {
      vasObj = tmpJourney.stages[stageIndex].steps[stepsIndex].vas;
    } else {
      vasObj = tmpJourney.stages[stageIndex].vas;
    }

    const valueVas: any = vasObj?.find(
      (vas: any) => vas?.id == selectedVas?.id
    );

    function setParameter() {
      let decIds: any = [];
      const decisionIds = selectSource === "eligibility" ? eligibilityDecision : offerDecision;
      const isEArray = e instanceof Array;
      decIds = valueVas?.parameters?.checkEligibilityAndOfferVasDto?.decisioningIds ?? [];
      decIds = (type == "decisionSelect" && isEArray) ? e : decIds;
      return {
        checkEligibilityAndOfferVasDto: {
          decisioningIds: decIds.length ? decIds : decisionIds ?? [],
        },
      }
    }

    let newVas: any = {
      id: selectedVas?.id,
      isEnabled: (() => {
        return type == "select" ? e : selectedVas?.isEnabled ?? runTypeStage
      })(),
      isBackend: type == "backEnd" ? e?.target?.checked : selectedVas?.isBackend ?? isBackend,
      canSkip: (() => {
        if ((selectedVas?.id === "CHECK_ELIGIBILITY" || selectedVas?.id === "CHECK_OFFER") && type == "check") {
          return e?.target?.checked
        }
        return type == "skip" ? e?.target?.checked : selectedVas?.canSkip ?? isSkippable ?? valueVas?.canSkip
      })(),
      parameters: setParameter(),
      isActive: (() => {
        if ((type == "skip" && e?.target?.checked) || (type == "select" && e?.length) || ((type == "backEnd" && e?.target?.checked))) {
          return true;
        }
        return type == "check" ? e?.target?.checked : valueVas?.isActive
      })() 
    };
    if ((tmpJourney?.stages[stageIndex]?.steps?.length ?? 0) == 0) {
      const tmpStageVasList = tmpJourney.stages[stageIndex].vas ?? [];
      const indexFind = tmpStageVasList?.findIndex(
        (item: any) => item?.id == selectedVas?.id
      );
      if (indexFind == -1) {
        tmpStageVasList.push(newVas);
      } else {
        if (type == "check") {
          tmpStageVasList[indexFind] = {
            ...newVas, canSkip: newVas?.isActive ? newVas?.canSkip : false, isEnabled: newVas?.isActive ? newVas?.isEnabled : undefined, isBackend: newVas?.isActive ? newVas?.isBackend : false
          };
        } else {
          tmpStageVasList[indexFind] = newVas;
        }
      }
      tmpJourney.stages[stageIndex].vas = tmpStageVasList;
      setVasData(tmpStageVasList);
    } else {
      const tmpStepVasList =
        tmpJourney.stages[stageIndex].steps[stepsIndex].vas ?? [];
      const indexFind = tmpStepVasList?.findIndex(
        (item: any) => item?.id == selectedVas?.id
      );
      if (indexFind == -1) {
        tmpStepVasList.push(newVas);
      } else {
        if (type == "check") {
          tmpStepVasList[indexFind] = { ...newVas, canSkip: newVas?.isActive ? newVas?.canSkip : false, isEnabled: newVas?.isActive ? newVas?.isEnabled : undefined, isBackend: newVas?.isActive ? newVas?.isBackend : false };
        } else {
          tmpStepVasList[indexFind] = newVas;
        }

      }
      tmpJourney.stages[stageIndex].steps[stepsIndex].vas = tmpStepVasList;
      setVasData(tmpStepVasList);
    }
    setJourneyData(tmpJourney);
  };

  const onDecisionCancelVAS = () => {
    const tmpJourney = { ...journeyData };
    if (isStep) {
      setConfigValueVAS(
        tmpJourney?.stages[stageIndex]?.steps[stepsIndex]?.vas[vasIndex]?.isEnabled
      );
      setConfigResultValVAS(tmpJourney?.stages[stageIndex]?.steps[stepsIndex]?.vas[vasIndex]?.stepDecisioningResult !== null ? tmpJourney?.stages[stageIndex]?.steps[stepsIndex]?.vas[vasIndex]?.stepDecisioningResult : true)
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].vas[vasIndex].stepDecisioningId =
        tmpJourney?.stages[stageIndex]?.steps[
          stepsIndex
        ]?.vas[vasIndex]?.stepDecisioningId;
      // result set
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].vas[vasIndex].stepDecisioningResult =
        tmpJourney?.stages[stageIndex]?.steps[
          stepsIndex
        ]?.vas[vasIndex]?.stepDecisioningResult;
    } else {
      setConfigValueVAS(
        tmpJourney?.stages[stageIndex]?.vas[vasIndex]?.stepDecisioningId
      );
      setConfigResultValVAS(tmpJourney?.stages[stageIndex]?.vas[vasIndex]?.stepDecisioningResult !== null ? tmpJourney?.stages[stageIndex]?.vas[vasIndex]?.stepDecisioningResult : true);
      tmpJourney.stages[stageIndex].vas[vasIndex].stepDecisioningId =
        tmpJourney?.stages[stageIndex]?.vas[vasIndex]?.stepDecisioningId;
      tmpJourney.stages[stageIndex].vas[vasIndex].stepDecisioningResult =
        tmpJourney?.stages[stageIndex]?.vas[vasIndex]?.stepDecisioningResult;

    }
    setJourneyData(tmpJourney);
    SetIsDecisionModalVAS(false);
  };

  const onDecisionSaveVAS = () => {
    const tmpJourney = { ...journeyData };
    if (isStep) {
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].vas[vasIndex].stepDecisioningId = configValueVAS;
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].vas[vasIndex].stepDecisioningResult = configResultValVAS;
    } else {
      tmpJourney.stages[stageIndex].vas[vasIndex].stepDecisioningId = configValueVAS;
      tmpJourney.stages[stageIndex].vas[vasIndex].stepDecisioningResult = configResultValVAS;
    }
    setJourneyData(tmpJourney);
    SetIsDecisionModalVAS(false);
  };

  const renderVAS = () => (
    <>
      {checkJourneyBuilderConfiguration?.vasSupported && (
        <div className="bg-Action" style={{
          marginTop: !checkJourneyBuilderConfiguration?.formSupported ||
            checkUserTaskDefinitionKey == null ? "10px" : "0px"
        }}>
          {!checkJourneyBuilderConfiguration?.formSupported ||
            checkUserTaskDefinitionKey == null ? <p className="action-names">IN-Stage Actions</p> : ""}
          <div >
            <span className="number-point">{!checkJourneyBuilderConfiguration?.formSupported ||
              checkUserTaskDefinitionKey == null ? 1 : 3}</span><span style={{ fontWeight: "500" }}>VALUE ADDED SERVICES(VAS)</span>
          </div>
          <Form layout="horizontal" className="vas-form" form={formVas} style={{ marginTop: "10px" }}>
            {vasList?.map((item: any, index: number) => {
              const valueVas: any = vasData?.find(
                (vas: any) => vas?.id == item?.id
              );
              formVas.setFieldsValue({
                [`run-type${index}`]: valueVas?.isEnabled,
                [`canSkip${index}`]: valueVas?.canSkip,
              });
              // TODO: display checked if vas find
              return (
                <>
                  <Space key={item?.id} style={{ padding: "0% 4.4%" }}>
                    <Form.Item
                      name={`vasCheck${index}`}
                      className="check-journery"
                    >
                      <Checkbox
                        onChange={(e: any) => {
                          if (item.id === "CHECK_ELIGIBILITY") {
                            onVasCheck(e, item, "check", "eligibility");
                            setIsSkippable(e?.target?.checked);
                          } else if (item.id === "CHECK_OFFER") {
                            onVasCheck(e, item, "check", "offer");
                            setIsSkippable(e?.target?.checked);
                          } else {
                            onVasCheck(e, item, "check")
                          }
                        }}
                        checked={valueVas?.isActive}
                        disabled={(workflowType === "BORROWER_DIRECT") && (item.id === "CHECK_ELIGIBILITY" || item.id === "CHECK_OFFER")}
                      >
                        {item?.name}
                      </Checkbox>
                    </Form.Item>

                    {(checkJourneyBuilderConfiguration?.conditionalVasSupported && !(item.id === "CHECK_ELIGIBILITY" || item.id === "CHECK_OFFER")) && (
                      <Form.Item
                        label="Run Type"
                        className="margin-rule"
                        name={`run-type${index}`}
                      >
                        <Space className="rule-space">
                          <Select
                            value={valueVas?.isEnabled}
                            onChange={(key) => {
                              setVasIndex(index);
                              setRunTypeStage(key);
                              onVasCheck(key, item, "select");
                              if (key === "CONDITIONAL") {
                                SetIsDecisionModalVAS(true);
                                // setSelectedVasData(item);
                                setConfigValueVAS(valueVas?.stepDecisioningId);
                              }
                            }}
                            className="select-loan"
                          >
                            {getEnumList("StepConfigValue")?.map(
                              (option: any, i: number) => (
                                <Select.Option key={i} value={option.key}>
                                  {option.value}
                                </Select.Option>
                              )
                            )}
                          </Select>
                          {formVas?.getFieldValue(`run-type${index}`) ===
                            "CONDITIONAL" && (
                              <img
                                src={editIcon}
                              alt=""
                                onClick={() => {
                                  SetIsDecisionModalVAS(true);
                                  // setSelectedVasData(item);
                                  setConfigValueVAS(valueVas?.stepDecisioningId);
                                  setConfigResultValVAS(valueVas?.stepDecisioningResult)
                                }}
                              />
                            )}
                        </Space>
                      </Form.Item>
                    )}
                    {(checkJourneyBuilderConfiguration?.backendVasSupported && !(item.id === "CHECK_ELIGIBILITY" || item.id === "CHECK_OFFER")) && (
                      <Form.Item
                        name={`isBackend${index}`}
                        className="check-journery"
                        style={{ marginLeft: "20px" }}
                      >
                        <Checkbox
                          onChange={(e: any) => {
                            setIsBackend(e?.target?.checked);
                            onVasCheck(e, item, "backEnd");
                          }}
                          checked={valueVas?.isBackend}
                        >
                          Backend Services
                        </Checkbox>
                      </Form.Item>
                    )}
                    <Form.Item
                      name={`canSkip${index}`}
                      className="check-journery"
                      style={{ marginLeft: "20px" }}
                    >
                      <Checkbox
                        onChange={(e: any) => {
                          setIsSkippable(e?.target?.checked);
                          onVasCheck(e, item, "skip");
                        }}
                        checked={valueVas?.canSkip}
                        disabled={item.id === "CHECK_ELIGIBILITY" || item.id === "CHECK_OFFER"}
                      >
                        Skippable
                      </Checkbox>
                    </Form.Item>
                  </Space>
                  {(item.id === "CHECK_ELIGIBILITY") && (
                    <Space className="rule-space">
                      <Form.Item name={`checkEligibility`}>
                        <Select
                          // value={decisioningData?.at(0)?.id}
                          onChange={
                            (key) => {
                              setVasIndex(index);
                              setEligibilityDecision(key);
                              onVasCheck(key, item, "decisionSelect", "eligibility")
                            }
                          }
                          onClear={
                            () => {
                              setVasIndex(index);
                              setEligibilityDecision([]);
                              onVasCheck([], item, "decisionSelect", "eligibility")
                            }
                          }
                          defaultValue={valueVas?.parameters?.checkEligibilityAndOfferVasDto?.decisioningIds ?? []}
                          disabled={(workflowType === "BORROWER_DIRECT")}
                          className="select-loan"
                          allowClear
                          mode="multiple"
                          style={{ width: "300px" }}
                        >
                          {decisioningList?.map((option: any, i: number) => (
                            option.value.includes("Decision Table") && (
                              <Select.Option key={i} value={option?.key}>
                                {option?.value}
                              </Select.Option>
                            )))}
                        </Select>
                      </Form.Item>
                    </Space>
                  )}
                  {(item.id === "CHECK_OFFER") && (
                    <Space className="rule-space">
                      <Form.Item name={`checkOffer`}>
                        <Select
                          onChange={
                            (key) => {
                              setVasIndex(index);
                              setOfferDecision(key);
                              onVasCheck(key, item, "decisionSelect", "offer")
                            }
                          }
                          onClear={
                            () => {
                              setVasIndex(index);
                              setEligibilityDecision([]);
                              onVasCheck([], item, "decisionSelect", "offer")
                            }
                          }
                          disabled={(workflowType === "BORROWER_DIRECT")}
                          defaultValue={valueVas?.parameters?.checkEligibilityAndOfferVasDto?.decisioningIds ?? []}
                          className="select-loan"
                          allowClear
                          mode="multiple"
                          style={{ width: "300px" }}
                        >
                          {decisioningList?.map((option: any, i: number) => (
                            (option.value.includes("Offer Decision") || option.value.includes("Score Card")) && (
                              <Select.Option key={i} value={option?.key}>
                                {option?.value}
                              </Select.Option>
                            )))}
                        </Select>
                      </Form.Item>
                    </Space>
                  )}
                  <Divider className="divider" style={{ margin: "10px 0px" }} />
                </>
              );
            })}
          </Form>

        </div>
      )}
    </>
  );

  const isPostStageActionVisible = checkJourneyBuilderConfiguration?.postDecisioningSupported || checkJourneyBuilderConfiguration?.dropOffStageSupported || checkJourneyBuilderConfiguration?.assistedJourneyNextStepSupported
  return (
    <>
      <div style={{ padding: "0px 20px" }}>
        {renderVAS()}
        <div className={isPostStageActionVisible ? "bg-Action" : "bg-Action-no-action"} style={{ marginBottom: "10px" }}>
          {isPostStageActionVisible && (<p className="action-names">POST-Stage Actions</p>)}
          {checkJourneyBuilderConfiguration?.postDecisioningSupported && (
            <Form
              style={{ width: "40%", padding: "0 25px", minWidth: "300px" }}
              className="decision-stage"
              form={formDecision}
              name="formDecision"
            >
              {/* {decisioningList?.map((item: any, index: number) => {
              return ( */}
              <Form.Item label="Post Stage Decision" name={`postDecision`}>
                <Select
                  // value={decisioningData?.at(0)?.id}
                  onChange={onPostDecision}
                  className="select-loan"
                  allowClear
                  mode="multiple"
                >
                  {decisioningList?.map((option: any, i: number) => (
                    <Select.Option key={i} value={option?.key}>
                      {option?.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
          <div style={{ padding: "0 25px", display: "flex", justifyContent: "space-between" }}>
            <Space style={{ gap: "20px" }}>
              {checkJourneyBuilderConfiguration?.dropOffStageSupported && (
                <Form.Item label="Drop Off Stage">
                  <Select
                    value={dropOfStageStep}
                    className="select-loan"
                    allowClear
                    placeholder="select type"
                    dropdownStyle={{ width: "250px" }}
                    onChange={onDropOfStageStatus}
                    style={{ width: 180 }}
                  >
                    {getEnumList("PROSPECT_ONBOARDING_STATUS")?.map(
                      (option: any, i: number) => (
                        <Select.Option key={i} value={option.key}>
                          {option.value}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              )}
              {checkJourneyBuilderConfiguration?.assistedJourneyNextStepSupported && (
                <Form.Item label="Next Assisted Journey Step:">
                  <Select
                    value={caseStatusStep}
                    className="select-loan"
                    allowClear
                    placeholder="select type"
                    dropdownStyle={{ width: "250px" }}
                    onChange={onStepCaseStatus}
                    style={{ width: 180 }}
                  >
                    {nextAssistedJourney?.map((option: any, i: number) => (
                      <Select.Option key={i} value={option.key}>
                        {option.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Space>
            <FormButton
              type="primary"
              style={{ width: "130px", height: "40px" }}
              size="large"
              label="Save"
              disabled={isSaveDisable}
              onclick={updatejourney}
            />
          </div>
        </div>
      </div>
      {isDecisionModalVAS && (
        <DecisionModel
          isDecisionModal={isDecisionModalVAS}
          checkJourneyBuilderConfiguration={checkJourneyBuilderConfiguration}
          displayConfigDecisionList={displayConfigDecisionList}
          configValue={configValueVAS}
          configResultVal={configResultValVAS}
          cancelClick={onDecisionCancelVAS}
          saveClick={onDecisionSaveVAS}
          selectChange={(key: any) => setConfigValueVAS(key)}
          radioChange={(e) => setConfigResultValVAS(e)}
        />
      )}
    </>
  );
};

export default JourneyFooterNew;
