import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Switch, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import search from "../../assets/images/search.svg";
import { API_SERVICE } from '../../shared/services/api-services';
import { useDispatch, useSelector } from 'react-redux';
import { setTenantPageNo, setTenantPageSize, setTenantSearchParam } from '../../shared/redux/tenant-reducer';
import { RootState } from '../../shared/redux/store';
import AddTenantLogo from './AddTenantLogo';
import FormIconButton from '../../shared/component/FormIconButton/FormIconButton';

interface columns {
    key: React.ReactNode;
    name: string;
    logo: any;
}

const DcConguration: React.FC = () => {
    const [assignToDataList, setAssignToDataList] = useState([]);
    const [editData, setEditData] = useState([] as any);
    const [openUploaDrawer, setOpenUploaDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const tenant: any = useSelector((state: RootState) => state.tenant);
    const [totalCount, setTotalCount] = useState(0);
    const [enableWorkingCapital, setEnableWorkingCapital] = useState(false)
    const [isOpenVerify, setIsOpenVerify] = useState(false);
    const [tenantId, setTenantId] = useState(String)
    const [showDecisioningAudit, setShowDecisioningAudit] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTenantPageNo(1));
        dispatch(setTenantPageSize(10));
        dispatch(setTenantSearchParam(""));
        getTenantList();
    }, []);

    const actionEdit = (record: any) => {
        setEditData(record)
        setOpenUploaDrawer(true)
    }

    const handlePaginationChange = (current: any, pageSize: any) => {
        dispatch(setTenantPageNo(current));
        dispatch(setTenantPageSize(pageSize));
        getTenantList(current, pageSize);
    };

    const handleSearch = (value: any) => {
        dispatch(setTenantPageNo(1));
        dispatch(setTenantSearchParam(value?.searchParam));
        getTenantList(1, tenant.pageSize, value?.searchParam);
    };

    const getTenantList = (
        pageNo: number = 1,
        pageSize: any = 10,
        searchParam: any = null
    ) => {
        setLoading(true);
        const params = {
            pageNo: pageNo,
            pageSize: pageSize,
            filterNameParam: searchParam
        };
        API_SERVICE.getListTenants(params)
            .then(({ data }) => {
                setAssignToDataList(data?.payload?.content ?? []);
                setTotalCount(data?.payload?.totalElements ?? 0);
            })
            .catch((e: any) => 
                API_SERVICE.handleErrors(e)
            )
            .finally(() => 
                setLoading(false)
            );
    };

    const handleWorkingCapital = (tenantId:any, workingCapitalEnabled:boolean) => {
        setIsOpenVerify(true)
        setTenantId(tenantId)
        setEnableWorkingCapital(workingCapitalEnabled)  
        renderEnableModal()
    }

    const handleOkay = ()=>{
        setIsOpenVerify(false);
        const params = {tenantWorkingCapitalEnabled: enableWorkingCapital};
        updateTenant(tenantId, params)
    }

    const handleCancel = ()=>{
        setIsOpenVerify(false);
    }

    const renderEnableModal = () => {
        return (
          <Modal
            className="text-center"
            title={enableWorkingCapital?`Are you sure you want to enable this ?`:`Are you sure you want to disable this ?`}
            open={isOpenVerify}
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "16px",
                }}
              >
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {handleOkay()}}
                  style={{ paddingInline: "30px" }}
                >
                  Yes
                </Button>
                <Button
                  key="back"
                  onClick={() => {handleCancel()
                }}
                  style={{ paddingInline: "30px" }}
                >
                  No
                </Button>
              </div>,
            ]}
            width={300}
            centered
            closable={false}
          ></Modal>
        );
      };

    const updateTenant = (
        tenetId: string,
        params: any
    ) => {
        setLoading(true);
        API_SERVICE.updateTenant(tenetId, params)
            .then(({ data }) => {
                getTenantList(tenant.pageNo, tenant.pageSize, tenant.searchParam);
            })
            .catch((e: any) => 
                API_SERVICE.handleErrors(e)
            )
            .finally(() => 
                setLoading(false)
            );
    };

    // const columns: ColumnsType<columns> = [
        const columns: ColumnsType<any> = [
        {
            title: 'Tenant Name',
            dataIndex: "businessName",
            key: 'businessName',
            // width: '40%'
        },
        {
            title: 'Logo',
            dataIndex: 'tenantLogoName',
            key: 'tenantLogoName',
        },
        {
            title: 'Tenant Type',
            dataIndex: 'businessProductType',
            key: 'businessProductType',
            width:"100px"
            
        },
        {
            title: 'Decisioning Audit',
            dataIndex: 'decisioningAudit',
            key: 'status',
            align: "center",
            width  :"150px",
            render: (_: any, record: any) => (
                <Switch checked={record.showDecisioningAudit} onChange={(value) => {
                    const params = { showDecisioningAudit: value }
                    updateTenant(record.businessInfoId, params);
                }}/>
            ),
        },
        {
            title: 'Working Capital',
            dataIndex: 'workingCapital',
            key: 'status',
            align: "center",
            width  :"130px",
            render: (_: any, record: any) => (
                <Switch 
                    checked={record.tenantWorkingCapitalEnabled} 
                    onChange={(value) => {
                        handleWorkingCapital(record.businessInfoId, value);
                    }}
                />
            ),
        },
        {
            title: 'Integrated Dialler',
            dataIndex: 'integratedDialler',
            key: 'status',
            align: "center",
            width  :"150px",
            render: (_: any, record: any) => (
                <Switch checked={record.dialerEnabled} onChange={(value) => {
                    const params = { dialerEnabled: value }
                    updateTenant(record.businessInfoId, params);
                }}/>
            ),
        },
        {
            title: 'Customer360',
            dataIndex: 'customer360',
            key: 'status',
            align: "center",
            width  :"110px",
            render: (_: any, record: any) => (
                <Tooltip title = {record.customer360Enabled?"Grants access to the Customer One View functionality for associated roles.":"Restricts access to the Customer One View functionality."}>
                <Switch 
                    checked={record.customer360Enabled} 
                    onChange={(value) => {
                    const params = { customer360Enabled: value }
                    updateTenant(record.businessInfoId, params);
                }}/>
                </Tooltip>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            fixed: "right",
            align:'center',
            width:"100px",
            render: (_: any, record: any) => (
                <FormIconButton
                    type={"edit"}
                    onClick={() => actionEdit(record)}
                />
            ),
        },
    ];

    return (
        <>
            {renderEnableModal()}
            <div className="layout-main-top">
                <Form layout="inline"
                    onFinish={handleSearch}
                >
                    <Form.Item name='searchParam'>
                        <Input
                            size="large"
                            className="search-input"
                            prefix={<img src={search} />}
                            placeholder="Search"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" style={{ height: '40px' }} htmlType="submit">Search</Button>
                    </Form.Item>
                </Form>
            </div>
            <div className={"layout-main"} >
                <Row style={{ padding: '15px' }}>
                    <Col xs={30} xl={24} span={24}>
                        <Table
                            columns={columns}
                            dataSource={assignToDataList}
                            size='middle'
                            loading={loading}
                            onChange={(pagination: any) => {
                                handlePaginationChange(pagination.current, pagination.pageSize);
                            }}
                            pagination={{
                                position: ['bottomCenter'],
                                current: tenant.pageNo,
                                pageSize: tenant.pageSize,
                                total: totalCount
                            }}
                            tableLayout='fixed'
                        
                            // scroll={{ x: 'max-content' }}
                        />
                    </Col>
                </Row>
            </div>

            {
                openUploaDrawer &&
                <AddTenantLogo
                    openUploaDrawer={openUploaDrawer}
                    setOpenUploaDrawer={setOpenUploaDrawer}
                    editData={editData}
                    getTenantList={getTenantList} />
            }
        </>
    );
};

export default DcConguration;