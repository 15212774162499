import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Tabs,
  Radio,
  notification,
  Checkbox,
  Space,
} from "antd";
// import "formiojs/dist/formio.builder.min.css";
// @ts-ignore
import { Form as Formio } from "react-formio";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import editIcon from "../../../assets/images/edit.svg";
import { API_SERVICE } from "../../../shared/services/api-services";
import { getEnumList } from "../../../shared/Utility/Utility";
import FormTextArea from "../../../shared/component/FormTextArea/FormTextArea";
import FormJosnDataList from "../ProductJourney/ProspectJourney/FormJosnDataList";
import FormButton from "../../../shared/component/FormButton/FormButton";
import "../ProductJourney/ProspectJourney/ProspectJourney.scss";
import "../ProductJourney/ProspectJourney/JourneyStep.scss";
import JourneyFooterNew from "./JourneyFooterNew";
import DecisionModel from "./DecisionModel";

type IJourneyStepNew = {
  workflowType: any;
  isStep: any;
  step: any;
  stage?: any;
  journeyData: any;
  setJourneyData: any;
  stageIndex: any;
  stepsIndex: any;
  displayConfigDecisionList: any;
  setIsSaveDisable: any;
  nextAssistedJourney: any;
  vasList: any;
  inactiveVasList: any;
  setVasData: any;
  vasData: any;
  productWorkflowId: any;
  decisioningData: any;
  decisioningOptions: any;
  isSaveDisable: any;
  getProductWorkflowById: any;
  decisioningList: any;
  setViewTypeJourney: any;
};

const JourneyStepNew: React.FunctionComponent<IJourneyStepNew> = (
  props: IJourneyStepNew
) => {
  const {
    workflowType,
    isStep,
    step,
    stage,
    journeyData,
    setJourneyData,
    stageIndex,
    stepsIndex,
    displayConfigDecisionList,
    setIsSaveDisable,
    nextAssistedJourney,
    vasList,
    inactiveVasList,
    setVasData,
    vasData,
    productWorkflowId,
    decisioningData,
    decisioningOptions,
    isSaveDisable,
    getProductWorkflowById,
    decisioningList,
    setViewTypeJourney,
  } = props;

  const [webFileData, setWebFileData] = useState(null as any);
  const [mobileFileData, setMobileFileData] = useState(null as any);
  const [openPreview, setOpenPreview] = useState(null as any);
  const [formList, setFormList] = useState([] as any);
  const [runTypeStep, setRunTypeStep] = useState(null as any);
  const [disableJson, setDisableJson] = useState(false);
  const [isDecisionModal, SetIsDecisionModal] = useState(false);
  const [tabKey, setTabKey] = useState("web");
  const [configValue, setConfigValue] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [selectedVasData, setSelectedVasData] = useState(null as any);
  const [formJson] = Form.useForm() as any;
  const [configResultVal, setConfigResultVal] = useState(false);
  const [isOfferReview, setIsOfferReview] = useState(false);
  const [isOfferJson, setIsOfferJson] = useState({} as any);
  const [offerReviewFormData, setOfferReviewFormData] = useState({} as any);
  const checkJourneyBuilderConfiguration = isStep
    ? journeyData?.stages?.at(stageIndex)?.steps?.at(stepsIndex)
      ?.journeyBuilderConfiguration
    : journeyData?.stages?.at(stageIndex)?.journeyBuilderConfiguration;
  const checkUserTaskDefinitionKey = isStep
    ? journeyData?.stages?.at(stageIndex)?.steps?.at(stepsIndex)
      ?.userTaskDefinitionKey
    : journeyData?.stages?.at(stageIndex)?.userTaskDefinitionKey;

  useEffect(() => {
    formListTemplate();
    if (isStep && stageIndex >= 0 && journeyData?.stages[stageIndex]?.steps) {
      setConfigValue(
        journeyData?.stages[stageIndex]?.steps[stepsIndex]?.runType?.stepDecisioningId
      );
      setConfigResultVal(
        journeyData?.stages[stageIndex]?.steps[stepsIndex]?.runType?.stepDecisioningResult !== null ? journeyData?.stages[stageIndex]?.steps[stepsIndex]?.runType?.stepDecisioningResult : true
      )
      if (isStep) {
        setOfferReviewFormData(journeyData?.stages[stageIndex]?.steps[stepsIndex]?.form?.parameters ?? {});
      } else {
        setOfferReviewFormData(journeyData?.stages[stageIndex]?.form?.parameters ?? {});
      }
    }
  }, []);

  useEffect(() => {
    let tmpObj = isStep ? step : stage;
    setRunTypeStep(tmpObj?.runType?.isEnabled);
    setConfigValue(tmpObj?.runType?.stepDecisioningId);
    setConfigResultVal(tmpObj?.runType?.stepDecisioningResult !== null ? tmpObj?.runType?.stepDecisioningResult : true)
  }, [isStep ? step : stage]);

  const formListTemplate = () => {
    API_SERVICE.formTemplateList()
      .then(({ data }) => {
        const list = data?.payload.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
            formJson: item?.formJson,
            mobileFormJson: item?.mobileFormJson,
            templateType: item?.templateType,
            componentCode: item?.componentCode,
            parametersDefinition: item?.parametersDefinition
          };
        });
        list.push({
          value: "CUSTOM",
          label: "Custom",
        });
        setFormList(list ?? []);
        const tmpJourneyForm = isStep
          ? journeyData?.stages[stageIndex]?.steps[stepsIndex]?.form
          : journeyData?.stages[stageIndex]?.form;
        setDisableJson(tmpJourneyForm?.templateId != null);
        const findFormValue = list?.find(
          (item: any) => item?.value == tmpJourneyForm?.templateId
        );
        setIsOfferReview(findFormValue?.templateType === "REACT_UI" && findFormValue?.parametersDefinition);
        setIsOfferJson(findFormValue?.parametersDefinition ?? {});
        formJson.setFieldsValue({
          selectedForm: findFormValue?.value,
          webJson: findFormValue?.formJson
            ? JSON?.stringify(findFormValue?.formJson ?? {}, undefined, 2)
            : tmpJourneyForm?.definition
              ? JSON?.stringify(tmpJourneyForm?.definition ?? {}, undefined, 2)
              : null,
          mobileJson: findFormValue?.mobileFormJson
            ? JSON?.stringify(findFormValue?.mobileFormJson ?? {}, undefined, 2)
            : tmpJourneyForm?.mobileDefinition
              ? JSON?.stringify(tmpJourneyForm?.mobileDefinition, undefined, 2)
              : null,
        });
        setTemplateType(findFormValue?.templateType)
        if (isStep) {
          setOfferReviewFormData(journeyData?.stages[stageIndex]?.steps[stepsIndex]?.form?.parameters ?? {});
        } else {
          setOfferReviewFormData(journeyData?.stages[stageIndex]?.form?.parameters ?? {});
        }
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const previewDataList = (values: any) => {
    try {
      setWebFileData(JSON?.parse(values?.webJson));
      setMobileFileData(JSON?.parse(values?.mobileJson ?? "{}"));
      const tmpJourney = { ...journeyData };
      if (isStep && tmpJourney?.stages[stageIndex]?.steps) {
        tmpJourney.stages[stageIndex].steps[stepsIndex].form = {
          definition: JSON?.parse(values?.webJson),
          mobileDefinition: JSON?.parse(values?.mobileJson ?? null),
          templateId: disableJson ? values?.selectedForm : null,
        };
      } else {
        tmpJourney.stages[stageIndex].form = {
          definition: JSON?.parse(values?.webJson),
          mobileDefinition: JSON?.parse(values?.mobileJson ?? null),
          templateId: disableJson ? values?.selectedForm : null,
        };
      }
      setJourneyData(tmpJourney);
      setOpenPreview(true);
      setIsSaveDisable(false);
    } catch (error) {
      notification.error({ message: "JSON not valid" });
    }
  };

  const onStepsRunType = (key: any) => {
    setRunTypeStep(key);
    const tmpJourney = { ...journeyData };
    if (isStep) {
      if (tmpJourney?.stages[stageIndex]?.steps[stepsIndex]?.runType == null) {
        tmpJourney.stages[stageIndex].steps[stepsIndex].runType = {
          isEnabled: key,
        };
      } else {
        tmpJourney.stages[stageIndex].steps[stepsIndex].runType.isEnabled = key;
      }
    } else {
      if (tmpJourney?.stages[stageIndex]?.runType == null) {
        tmpJourney.stages[stageIndex].runType = {
          isEnabled: key,
        };
      } else {
        tmpJourney.stages[stageIndex].runType.isEnabled = key;
      }
    }
    setJourneyData(tmpJourney);
    if (key === "CONDITIONAL") {
      SetIsDecisionModal(true);
    }
  };

  const onSelectForm = (value: any) => {
    const data = formList?.find((item: any) => item?.value == value);
    setTemplateType(data?.templateType);
    setIsOfferReview(data?.templateType === "REACT_UI" && data.parametersDefinition);
    setIsOfferJson(data.parametersDefinition);
    if (data?.templateType == "REACT_UI") {
      setIsSaveDisable(false);
      const tmpJourney = { ...journeyData };
      if (isStep && tmpJourney?.stages[stageIndex]?.steps) {
        tmpJourney.stages[stageIndex].steps[stepsIndex].form = {
          templateId: value,
          componentCode: data?.componentCode,
        };
      } else {
        tmpJourney.stages[stageIndex].form = {
          templateId: value,
          componentCode: data?.componentCode,
        };
      }
      setJourneyData(tmpJourney);
    } else {
      setIsSaveDisable(true);
      setDisableJson(value != "CUSTOM");
      setOpenPreview(false);
      formJson.setFieldsValue({
        webJson: data?.formJson
          ? JSON.stringify(data?.formJson ?? {}, undefined, 2)
          : null,
        mobileJson: data?.mobileFormJson
          ? JSON.stringify(data?.mobileFormJson ?? {}, undefined, 2)
          : null,
      });
    }
  };

  const handleOnChange = (submission: any) => {
    if ((submission?.changed?.component?.key ?? "") === "viewOffersDescending" || (submission?.changed?.component?.key ?? "") === "thankyouMessage" || (submission?.changed?.component?.key ?? "") === "number" || (submission?.changed?.component?.key ?? "") === "displayLenderLoginOffers" || (submission?.changed?.component?.key ?? "") === "showBreOfferOnlyWhenLenderOfferSanctioned") {
      const tmpJourney = { ...journeyData };
      if (isStep) {
        if (tmpJourney.stages[stageIndex].steps[stepsIndex]?.form && isOfferReview) {
          const updateData = {
            viewOffersDescending: submission?.data?.viewOffersDescending,
            thankyouMessage: submission?.data?.thankyouMessage,
            number: submission?.data?.number,
            displayLenderLoginOffers: submission?.data?.displayLenderLoginOffers,
            showBreOfferOnlyWhenLenderOfferSanctioned: submission?.data?.showBreOfferOnlyWhenLenderOfferSanctioned
          }
          setOfferReviewFormData(updateData);
          tmpJourney.stages[stageIndex].steps[stepsIndex].form.parameters = updateData ?? null;
        }
      } else {
        if (tmpJourney.stages[stageIndex]?.form && isOfferReview) {
          const updateData = {
            viewOffersDescending: submission?.data?.viewOffersDescending,
            number: submission?.data?.number
          }
          setOfferReviewFormData(updateData);
          tmpJourney.stages[stageIndex].form.parameters = updateData ?? null;
        }
      }
    }
  }

  const onDecisionCancel = () => {
    const tmpJourney = { ...journeyData };
    if (isStep) {
      setConfigValue(
        tmpJourney?.stages[stageIndex]?.steps[stepsIndex]?.runType
          ?.stepDecisioningId
      );
      setConfigResultVal(tmpJourney?.stages[stageIndex]?.steps[stepsIndex]?.runType?.stepDecisioningResult !== null ? tmpJourney?.stages[stageIndex]?.steps[stepsIndex]?.runType?.stepDecisioningResult : true)
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].runType.stepDecisioningId =
        tmpJourney?.stages[stageIndex]?.steps[
          stepsIndex
        ]?.runType?.stepDecisioningId;
      // result set
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].runType.stepDecisioningResult =
        tmpJourney?.stages[stageIndex]?.steps[
          stepsIndex
        ]?.runType?.stepDecisioningResult;
    } else {
      setConfigValue(
        tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningId
      );
      setConfigResultVal(tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningResult !== null ? tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningResult : true);
      tmpJourney.stages[stageIndex].runType.stepDecisioningId =
        tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningId;
      tmpJourney.stages[stageIndex].runType.stepDecisioningResult =
        tmpJourney?.stages[stageIndex]?.runType?.stepDecisioningResult;

    }
    setJourneyData(tmpJourney);
    SetIsDecisionModal(false);
  };

  const onDecisionSave = () => {
    const tmpJourney = { ...journeyData };
    if (isStep) {
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].runType.stepDecisioningId = configValue;
      tmpJourney.stages[stageIndex].steps[
        stepsIndex
      ].runType.stepDecisioningResult = configResultVal;
    } else {
      tmpJourney.stages[stageIndex].runType.stepDecisioningId = configValue;
      tmpJourney.stages[stageIndex].runType.stepDecisioningResult = configResultVal;
    }
    setJourneyData(tmpJourney);
    SetIsDecisionModal(false);
  };

  const renderPreStageAction = () => (
    <div style={{ float: "left", width: "100%", paddingLeft: "3.4%" }}>
      <div style={{ width: "25%", display: "flex" }}>
        <Form.Item label="Run Type" style={{ width: 200 }}>
          <Select
            value={runTypeStep}
            disabled={
              checkJourneyBuilderConfiguration?.stepRunTypeDisabled ??
              false
            }
            allowClear
            className="select-loan"
            placeholder="select type"
            onChange={onStepsRunType}
          >
            {getEnumList("StepConfigValue")?.map(
              (option: any, i: number) => (
                <Select.Option key={i} value={option.key}>
                  {option.value}
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>
        {runTypeStep === "CONDITIONAL" && (
          <Form.Item>
            <img
              src={editIcon}
              onClick={() => SetIsDecisionModal(true)}
            />
          </Form.Item>
        )}
      </div>
    </div>
  );

  const renderWebPageCondition = () => (
    <div style={{ padding: "1% 3.4%" }}>
          {checkJourneyBuilderConfiguration?.formSupported &&
            checkUserTaskDefinitionKey != null ? (
            <Form.Item label="Form" name={"selectedForm"}>
              <Select
                style={{ width: "150px" }}
                options={formList}
                allowClear
                className="select-loan"
                dropdownStyle={{ width: "250px" }}
                defaultValue={"CUSTOM"}
                onChange={onSelectForm}
              />
            </Form.Item>
          ) : (
            ""
          )}
    </div>
  );

  const renderDataInputDetails = () => (
    <div style={{ padding: "1% 3.4%" }}>
      {isOfferReview && (
        <div className="form-offer-screen">
          <Formio
            submission={{ data: offerReviewFormData }}
            key={"jsonForm"}
            form={isOfferJson}
            jsonSchema={isOfferJson}
            onChange={debounce(handleOnChange, 500)}
          />
        </div>
      )}
      {templateType !== "REACT_UI" &&
        checkJourneyBuilderConfiguration?.formSupported &&
        checkUserTaskDefinitionKey != null ? (
        <Row>
          <Col span={12}>
            <p>FROM JSON</p>
            <FormTextArea
              classNameForm="space-input disabled-text"
              isBorder={true}
              row={10}
              name="webJson"
              placeholder="Enter Form Json"
              isRequired
              requiredLabel="Form Json"
              isDisabled={disableJson}
              onChange={() => {
                setOpenPreview(false);
                setIsSaveDisable(true);
              }}
            />

            <Button
              htmlType="submit"
              type="primary"
              size="large"
              style={{ marginTop: "10px" }}
            >
              Generate Preview
            </Button>
          </Col>
          <Col span={12} style={{ padding: "35px 35px" }}>
            {openPreview && (
              <Card title="Preview" className="card-Preview">
                <div
                  style={{
                    height: "250px",
                    overflow: "auto",
                    padding: "20px",
                  }}
                >
                  <FormJosnDataList
                    fileData={
                      tabKey == "web" ? webFileData : mobileFileData
                    }
                  />
                </div>
              </Card>
            )}
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );

  const renderStageOrStep = () => (
    <Form layout="inline" form={formJson} onFinish={previewDataList}>
      <div style={{ width: "100%", padding: "0px 20px" }}>
        {isStep && (
          <div className="bg-Action" style={{ height: "100px" }}>
            <p className="action-names">PRE-Stage Actions</p>
            {renderPreStageAction()}
          </div>
        )}
        {(checkJourneyBuilderConfiguration?.formSupported && checkUserTaskDefinitionKey != null) ? (
          <div className="bg-Action">
            <p className="action-names">IN-Stage Actions</p>
            <span className="number-point">1</span><span style={{ fontWeight: "500" }}>WEB PAGE CONDITIONS</span>
            {renderWebPageCondition()}
            <span className="number-point">2</span><span style={{ fontWeight: "500" }}>DATA INPUT FROM DETAILS</span>
            {renderDataInputDetails()}
          </div>
        ) : ""}
      </div>
    </Form>
  );

  const renderJourneyFooter = () => (
    <JourneyFooterNew
      workflowType={workflowType}
      productWorkflowId={productWorkflowId}
      isStep={isStep}
      decisioningData={isStep
        ? decisioningData
        : stage?.decisioning}
      decisioningOptions={decisioningOptions}
      stage={stage}
      step={step}
      nextAssistedJourney={nextAssistedJourney}
      journeyData={journeyData}
      setJourneyData={setJourneyData}
      stageIndex={stageIndex}
      stepsIndex={stepsIndex}
      getProductWorkflowById={getProductWorkflowById}
      isSaveDisable={isSaveDisable}
      decisioningList={decisioningList}
      displayConfigDecisionList={displayConfigDecisionList}
      setViewTypeJourney={setViewTypeJourney}
      vasList={vasList}
      inactiveVasList={inactiveVasList}
      vasData={vasData}
      setVasData={setVasData} />
  );

  return (
    <>
      {renderStageOrStep()}
      {isDecisionModal && (
        <DecisionModel
          isDecisionModal={isDecisionModal}
          checkJourneyBuilderConfiguration={checkJourneyBuilderConfiguration}
          displayConfigDecisionList={displayConfigDecisionList}
          configValue={configValue}
          configResultVal={configResultVal}
          cancelClick={onDecisionCancel}
          saveClick={onDecisionSave}
          selectChange={(key: any) => setConfigValue(key)}
          radioChange={(e) => setConfigResultVal(e)} />
      )}
      {renderJourneyFooter()}
    </>
  );
};

export default JourneyStepNew;
